<template>
    <div class="individualWrapper rounded-md shadow p-1 border border-slate-200">
        <div class="ScoreWrapper" :style="`background-color: ${this.$route.path === '/activity' && '#fff'} `">
            <div class="flex flex-row content-start items-center w-[70%]">
                <div class="svgBG border border-slate-300">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.5013 12.5C15.1257 12.5 17.2513 10.3744 17.2513 7.75C17.2513 5.12562 15.1257 3 12.5013 3C9.87695 3 7.75133 5.12562 7.75133 7.75C7.75133 10.3744 9.87695 12.5 12.5013 12.5ZM12.5 14.8746C9.32937 14.8746 3 16.4658 3 19.6246V20.8121C3 21.4652 3.53438 21.9996 4.1875 21.9996H20.8125C21.4656 21.9996 22 21.4652 22 20.8121V19.6246C22 16.4658 15.6706 14.8746 12.5 14.8746Z"
                            fill="#00AEF0"
                        />
                    </svg>
                </div>
                <h3>{{ $t("Individual Score") }}</h3>
            </div>

            <div v-for="(score, index) in candidateScore" :key="index" class="w-full border border-slate-200 rounded-md">
                <div class="score justify-between items-center" v-if="score?.rangesPoint">
                    <div class="relative w-[95%] flex flex-col justify-between items-center gap-2">
                        <div class="flex flex-row w-full justify-start gap-3 items-center">
                            <h4 class="text-left text-base font-semibold text-slate-700">{{ score.assessmentName }}</h4>
                            <h4 class="text-left text-base font-light text-slate-500">
                                {{ rank ? candidateRank[score.assessmentName] : (parseInt(score?.rangesPoint * 100) / (score?.quesionsNbr * 5)).toFixed() + "%" }}
                            </h4>
                        </div>
                        <!-- <span>{{ score.assessmentName }}:{{ "  " }} {{ (parseInt(score?.rangesPoint * 100) / (score?.quesionsNbr * 5)).toFixed(2) }}%</span> -->
                        <!-- <input
                            type="range"
                            :value="(score?.rangesPoint * 100) / (score?.quesionsNbr * 5)"
                            disabled
                            id="success-slider"
                            :style="{
                                background: `linear-gradient(90deg, #2196f3 ${(score?.rangesPoint * 100) / (score?.quesionsNbr * 5)}%, #f4f7fe ${
                                    (score?.rangesPoint * 100) / (score?.quesionsNbr * 5)
                                }%)`,
                            }"
                            @mouseover="toggleShowTooltip(index)"
                            @mouseleave="toggleShowTooltip(index)"
                        /> -->

                        <div class="w-full h-3 rounded-full bg-[#2195f34f] shadow-[0_0_1px_1px_rgba(0,0,0,15%)]" @mouseover="toggleShowTooltip(index)" @mouseleave="toggleShowTooltip(index)">
                            <div class="h-full rounded-full bg-[#2196f3]" :style="{ width: `${(score?.rangesPoint * 100) / (score?.quesionsNbr * 5)}%` }"></div>
                        </div>
                        <div
                            :id="'tooltip-' + index"
                            class="tooltip"
                            v-if="showTooltip[index]"
                            :style="{
                                left:
                                    (score?.rangesPoint * 100) / (score?.quesionsNbr * 5) > 50
                                        ? `${(score?.rangesPoint * 100) / (score?.quesionsNbr * 5) - 5}%`
                                        : `${(score?.rangesPoint * 100) / (score?.quesionsNbr * 5)}%`,
                            }"
                        >
                            {{ ((score?.rangesPoint * 100) / (score?.quesionsNbr * 5)).toFixed() }}%
                        </div>

                        <div
                            :id="'tooltip-' + index"
                            class="tooltip"
                            v-if="showTooltip[index]"
                            :style="{
                                left:
                                    (score.rangesPoint * 100) / score.quesionsNbr > 50
                                        ? `${(score.rangesPoint * 100) / (score?.quesionsNbr * 5) - 5}%`
                                        : `${(score.rangesPoint * 100) / (score?.quesionsNbr * 5)}%`,
                            }"
                        >
                            {{ ((score.totalPoints * 100) / score.quesionsNbr).toFixed(2) }}%
                        </div>
                    </div>
                    <div v-if="getInterceptionTitle(score.assessmentName)" class="text-[#2196f3]" @click="toggleSlider(index)">
                        <font-awesome-icon :icon="['fas', 'chevron-down']" />
                    </div>
                </div>
                <div class="score justify-between items-center" v-if="!score.rangesPoint && !score.personalityResults && !score.customResults">
                    <div class="relative w-[95%] flex flex-col justify-between items-center gap-2">
                        <div class="flex flex-row w-full justify-start gap-3 items-center">
                            <h4 class="text-left text-base font-semibold text-slate-700">{{ score.assessmentName }}</h4>
                            <h4 class="text-left text-base font-light text-slate-500">
                                {{ rank ? candidateRank[score.assessmentName] : (parseInt(score?.totalPoints * 100) / score?.quesionsNbr).toFixed() + "%" }}
                            </h4>
                        </div>

                        <div class="w-full h-3 rounded-full bg-[#2195f34f] shadow-[0_0_1px_1px_rgba(0,0,0,15%)]" @mouseover="toggleShowTooltip(index)" @mouseleave="toggleShowTooltip(index)">
                            <div class="h-full rounded-full bg-[#2196f3]" :style="{ width: `${(score?.totalPoints * 100) / score?.quesionsNbr}%` }"></div>
                        </div>
                        <div
                            :id="'tooltip-' + index"
                            class="tooltip"
                            v-if="showTooltip[index]"
                            :style="{
                                left:
                                    (score.totalPoints * 100) / score.quesionsNbr > 50 ? `${(score.totalPoints * 100) / score.quesionsNbr - 5}%` : `${(score.totalPoints * 100) / score.quesionsNbr}%`,
                            }"
                        >
                            {{ ((score.totalPoints * 100) / score.quesionsNbr).toFixed() }}%
                        </div>
                    </div>
                    <div v-if="getInterceptionTitle(score.assessmentName)" class="text-[#2196f3]" @click="toggleSlider(index)">
                        <font-awesome-icon :icon="['fas', 'chevron-down']" />
                    </div>
                </div>

                <!-- <transition name="slide"> -->
                <!-- <div class="slider-content" :style="`transition: all .5s ease ;height: ${isSliderOpen[index] ? '300px' : '0px'}`"> -->
                <div
                    class="slider-content"
                    v-if="isSliderOpen[index]"
                    :style="`transition: all .5s ease ;transition-delay: 0.25s ;height: ${isSliderOpen[index] ? '200px' : '0px'}; background-color: ${isSliderOpen[index] ? '#fff' : 'none'} `"
                >
                    <h2 class="text-md text-[#0f172a] my-3" style="font-weight: 600" v-if="isSliderOpen[index]">
                        {{ getInterceptionTitle(score.assessmentName) }}
                    </h2>
                    <p v-if="isSliderOpen[index]">
                        {{ getAssessmentInterception(score.assessmentName) }}
                    </p>
                </div>
                <!-- </div> -->
                <!-- </transition> -->

                <div class="w-full rounded-md" v-if="score.personalityResults && !score.customResults">
                    <BigFive :results="score" :show="showBig5" :toggleModal="toggleBig5" />
                    <div class="score justify-between items-center">
                        <div class="relative w-[95%] flex flex-col justify-between items-center gap-1">
                            <div class="flex flex-row w-full justify-between gap-3 items-center">
                                <h4 class="text-left text-base font-semibold text-slate-700">
                                    {{ score.assessmentName }}
                                    (Personality Test)
                                </h4>
                                <div class="flex flex-row gap-2 items-center">
                                    <div class="grid grid-cols-5 gap-1">
                                        <div
                                            v-for="(personalityResult, index) in getTraits(score.personalityResults)"
                                            :key="index"
                                            class="flex items-center justify-center w-[25px] h-[25px] rounded-[5px]"
                                            :class="{
                                                'bg-[#EA4745] text-white': personalityResult.degree === 'Very Low',
                                                'bg-[#ffa500]': personalityResult.degree === 'Low',
                                                'bg-[#ffff00]': personalityResult.degree === 'Medium',
                                                'bg-[#46A997] text-white': personalityResult.degree === 'High',
                                                'bg-[#008000]': personalityResult.degree === 'Very High',
                                            }"
                                        >
                                            <p>{{ personalityResult.name.charAt(0) }}</p>
                                        </div>
                                    </div>
                                    <button @click="toggleBig5" class="w-fit px-4 py-2 rounded text-base font-normal bg-NeonBlue text-white">Report</button>
                                </div>
                            </div>
                        </div>
                        <!-- <div v-if="true" class="text-[#2196f3]" @click="big5Result = !big5Result">
                            <font-awesome-icon :icon="['fas', 'chevron-down']" />
                        </div>-->
                    </div>
                    <!--  <div class="slider-content" v-if="big5Result"
                        :style="`transition: all .5s ease ;transition-delay: 0.25s ;height: ${big5Result ? '200px' : '0px'}; background-color: ${isSliderOpen[index] ? '#fff' : 'none'} `">
                        <h2 class="text-md text-[#0f172a] my-3" style="font-weight: 600" v-if="big5Result">the Big 5
                        </h2>
                        <p v-if="big5Result">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur eligendi atque id a
                            molestias quaerat, ea asperiores commodi ut reprehenderit! Provident fugiat doloremque
                            amet quae tempore, quam enim repudiandae ducimus.
                        </p>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BigFive from "./BigFive.vue";
export default {
    name: "IndividualScore",
    props: {
        candidateScore: Array,
        candidateInterpretations: Array,
        candidateRank: Object,
        rank: Boolean,
    },
    components: {
        BigFive,
    },
    mounted() {
        console.log("Results score", this.candidateScore);
    },
    data() {
        return {
            showBig5: false,
            big5Result: false,
            big5: true,
            showTooltip: Array(this.candidateScore?.length).fill(false),
            isSliderOpen: Array(this.candidateScore?.length).fill(false), // Array to track whether each slider is open or not
        };
    },
    methods: {
        toggleSlider(index) {
            // Toggle the state of the slider when the icon is clicked
            this.isSliderOpen[index] = !this.isSliderOpen[index];
        },
        toggleShowTooltip(index) {
            this.showTooltip[index] = !this.showTooltip[index];
        },
        toggleBig5() {
            this.showBig5 = !this.showBig5;
        },
        getAssessmentInterception(assessmentName) {
            console.log({ INETRCEPTIONSSSSSSSSSSSSSSSS: this.candidateInterpretations });
            let interception = this.candidateInterpretations?.find((interception) => interception.assessmentName === assessmentName);
            console.log({ interception });
            return interception?.description;
        },
        getInterceptionTitle(assessmentName) {
            let interception = this.candidateInterpretations?.find((interception) => interception.assessmentName === assessmentName);
            console.log({ interception });
            return interception?.title;
        },
        getTraits(results) {
            console.log("getTraits", results);
            return Object.keys(results).map((key) => {
                console.log({ key });
                return { name: key, degree: results[key] };
            });
        },
    },
};
</script>

<style scoped lang="scss">
#success-slider {
    -webkit-appearance: none;
    appearance: none;
    // background: linear-gradient(90deg, #2196f3 70%, #fff 70%);
    // border: 1px solid #2196f3;
    background-color: #f4f7fe;
    border-radius: 90px;
    margin-right: 48px;
    width: 95%;
    height: 15px;
}

#success-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    // margin-left: -1px;
    border-radius: 50%;
    background: #2196f3;
    border: 3px solid #2196f3;
    cursor: pointer;
    transform: translateX(-50%);
}

#success-slider[value="0"]::-webkit-slider-thumb {
    display: none;
}

.tooltip {
    position: absolute;
    bottom: -100%;
    // left: 50%;
    transform: translateX(-50%);
    background-color: #00000086;
    color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 25;
}

.dot {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 55%;
}

.dot::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #2196f3;
    display: block;
    margin-right: 5px;
}

.viewbtn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    border: none;
    letter-spacing: -0.02em;
    background: none;
    color: #2196f3;
    margin-left: 55%;
}

.viewbtn:hover {
    text-decoration: underline #2196f3;
}

.individualWrapper {
    // display: grid;
    // grid-template-columns: 65% 33%;

    gap: 15px;

    > :nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.ScoreWrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 5px;
    padding: 3%;
    background-color: #fff;

    > :first-child {
        margin-bottom: 16px;
        color: #343637;
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        /* 133.333% */
        letter-spacing: -0.48px;
    }
}

.score {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    // margin-bottom: 1px;
    // margin-left: 2%;
    transition: all 0.3s ease-in-out;
    border-radius: 6px;
    padding: 1rem;
    width: 100%;
    height: 100%;
    background-color: #fff;

    &:hover {
        cursor: pointer;
        background-color: #2371b631;
    }

    span {
        color: #343637;
        font-family: DM Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 10px;
        /* 76.923% */
        letter-spacing: -0.26px;
    }
}

.svgBG {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: var(--Secondary-Grey-300, #f4f7fe);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

h3 {
    color: #1b2559;
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
    margin: 0;
}

.slider-content {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;
    /* Use height for the transition */
    margin-left: 2%;
    padding: 1%;

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.32px;
        color: #343637;
    }
}
</style>
