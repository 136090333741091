<template>
    <div class="bg-white text-slate-700 px-4 py-8 md:px-16 md:py-4 h-fit mt-0">
        <div class="grid grid-cols-2 md:flex md:justify-between w-full">
            <div class="contact flex flex-col items-center md:items-start w-full md:w-1/3">
                <a href="/" class="h-fit mb-4">
                    <img loading="lazy" decoding="async" src="../assets/Images/footer_logo.svg" class="w-32 h-32 md:w-64 md:h-36" alt="Footer Logo" />
                </a>
                <a href="/contact" class="w-fit h-fit p-4 px-8 rounded-full border border-gray-100 shadow-card bg-white text-NeonBlue text-center font-bold text-xl mb-4">
                    {{ $t("Contact us") }}
                </a>
            </div>
            <!-- <div class="buttons flex flex-wrap justify-start md:justify-between w-full md:w-2/3 lg:w-full lg:grid lg:grid-cols-3 gap-4 lg:gap-10"> -->
            <nav class="w-full md:w-1/3 p-2">
                <h3 class="font-bold text-lg w-full text-left mb-3">{{ $t("Company") }}</h3>
                <div class="footer_links flex flex-col items-start justify-between font-light">
                    <a href="#" class="text-left font-thin text-sm my-2">{{ $t("Company") }}</a>
                    <a href="/contactUs" class="text-left font-thin text-sm my-2">{{ $t("Contact us") }}</a>
                    <a href="/Talents" class="text-left font-thin text-sm my-2">{{ $t("Talents") }}</a>
                </div>
            </nav>
            <nav class="w-full md:w-1/3 p-2">
                <h3 class="font-bold text-lg w-full text-left mb-3">{{ $t("Services") }}</h3>
                <div class="footer_links flex flex-col items-start justify-between font-light">
                    <a href="#" class="text-left font-thin text-sm my-2">{{ $t("Services") }}</a>
                    <a href="/planning" class="text-left font-thin text-sm my-2">{{ $t("Pricing") }}</a>
                    <a href="/articles" class="text-left font-thin text-sm my-2">{{ $t("Blogs") }}</a>
                </div>
            </nav>
            <nav class="w-full md:w-1/3 p-2">
                <h3 class="font-bold text-lg w-full text-left mb-3">{{ $t("Ressources") }}</h3>
                <div class="footer_links flex flex-col items-start justify-between font-light">
                    <a href="/articles" class="w-full text-left font-thin text-sm my-2">{{ $t("Blogs") }}</a>
                    <a href="/Terms-of-use" class="w-full text-left font-thin text-sm my-2">{{ $t("Terms") }}</a>
                    <a href="/privacy-policy" class="w-full text-left font-thin text-sm my-2">{{ $t("Privacy policy") }}</a>
                </div>
            </nav>
            <!-- </div> -->
            <div class="bottoms flex flex-col gap-1 mt-1">
                <div class="flex flex-col-reverse md:flex-row justify-between items-center w-full">
                    <div class="flex flex-row justify-end items-center pr-10 gap-8 pb-2">
                        <a href="https://www.facebook.com/GO.Platform" target="_blank" class="bottom_logo">
                            <!-- <img loading="lazy" decoding="async" src="../assets/Images/icons/icon_facebook.svg" alt="Facebook Icon" class="w-20 h-20" /> -->
                            <font-awesome-icon :icon="['fab', 'facebook-f']" class="text-[25px] text-[#fff]" />
                        </a>
                        <a href="https://www.linkedin.com/company/go-platform/posts/?feedView=all" target="_blank" class="bottom_logo">
                            <font-awesome-icon :icon="['fab', 'linkedin-in']" class="text-[25px] text-[#fff]" />
                        </a>
                        <a href="https://www.instagram.com/go.platform/" target="_blank" class="bottom_logo">
                            <font-awesome-icon :icon="['fab', 'instagram']" class="text-[25px] text-[#fff]" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <p class="w-full h-fit text-left text-base font-light text-gray-500 mt-4">{{ $t("believe") }}</p>

        <hr class="h-[5px] bg-[#F5F8FF] w-full" />
        <div class="text-left">
            <span class="text-xs font-thin text-gray-400">{{ $t("GO Platform All rights reserved") }}</span>
        </div>
        <div class="w-full items-center justify-center flex relative">
            <h1 class="max-w-[80%] pt-6 pb-2 text-2xl lg:text-4xl font-bold bg-gradient-to-r from-[#5d5fef] to-[#3db5e6] inline-block text-transparent bg-clip-text">
                Unlock the full potential of your workforce
            </h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterComponent",
};
</script>

<style lang="scss" scoped>
.footerWrapper {
    grid-template-areas:
        "contact buttons buttons"
        "contact buttons buttons"
        "bottoms bottoms bottoms";
}

.bottom_logo {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2196f3;
    color: #fff;
    border-radius: 8px;
}
</style>
