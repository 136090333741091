<template>
    <div class="interceptionsWrapper">
        <InterceptionCard v-for="(interception, index) in interceptions" :interception="interception" :key="index" />
    </div>
</template>

<script>
import InterceptionCard from "./InterceptionCard.vue";
export default {
    name: "InterceptionsSection",
    components: {
        InterceptionCard,
    },
    data() {
        return {
            interceptions: [
                {
                    assessmentName: "Time Management",
                    interceptions: [
                        {
                            range: [0, 33],
                            title: "The Time Voyager",
                            description:
                                "Individuals in this range may need to embark on a journey to master time. Challenges in prioritizing, scheduling, and meeting deadlines might require navigation skills to sail smoothly through the sea of time.",
                        },
                        {
                            range: [34, 66],
                            title: "The Clock Conductor",
                            description:
                                "Those in this range possess a moderate mastery of time. They can orchestrate tasks and schedules, yet there's room for refinement. With a bit of fine-tuning, they could lead a symphony of productivity.",
                        },
                        {
                            range: [67, 100],
                            title: "The Time Alchemist",
                            description:
                                "Individuals with high scores have an alchemical touch on time. They can transform moments into accomplishments, balancing priorities effortlessly. Their skill at transmuting seconds into success marks them as true time artisans.",
                        },
                    ],
                },
                {
                    assessmentName: "Motivation",
                    interceptions: [
                        {
                            range: [0, 33],
                            title: "Spark Seeker",
                            description:
                                "Individuals in this range might be on a quest to discover the sparks that ignite their motivation. The journey involves exploring inner drives and external influences to fuel the flames of inspiration.        ",
                        },
                        {
                            range: [34, 66],
                            title: "The Resilient Dynamo",
                            description:
                                "Those in this range exhibit a moderate level of motivation, displaying resilience in the face of challenges. They have the potential to become dynamic forces by honing their determination and channeling it towards their goals.",
                        },
                        {
                            range: [67, 100],
                            title: "The Soaring Enthusiast",
                            description:
                                "Individuals with high scores are like zeppelins of enthusiasm, soaring to great heights. They possess a sustained, high-flying motivation that propels them towards ambitious goals, making them true enthusiasts in their pursuits.",
                        },
                    ],
                },

                {
                    assessmentName: "Enterpreneurship - Easy",
                    interceptions: [
                        {
                            range: [0, 33],
                            title: "Startup Explorer",
                            description:
                                "Individuals in this range are at the starting line of their entrepreneurial journey. They may need to explore the landscape, identify opportunities, and gather the resources required to embark on their business adventure.",
                        },
                        {
                            range: [34, 66],
                            title: "Enterprise Novice",
                            description:
                                "Those in this range have a basic understanding of entrepreneurship. They may need to refine their skills and gain more hands-on experience to evolve from an apprentice into a skilled practitioner in the world of business.",
                        },
                        {
                            range: [67, 100],
                            title: "Startup Maestro",
                            description:
                                "Individuals with high scores are virtuosos in the entrepreneurial symphony. They possess a solid understanding of business principles, strategies, and innovation. Their entrepreneurial prowess positions them as maestros orchestrating successful startups.",
                        },
                    ],
                },

                {
                    assessmentName: "Entrepreneurship - Hard",
                    interceptions: [
                        {
                            range: [0, 33],
                            title: "The Business Explorer",
                            description:
                                "viduals in this range are navigating the challenging terrain of entrepreneurship. They may need to explore intricate business landscapes, identify advanced opportunities, and overcome substantial hurdles on their entrepreneurial journey.",
                        },
                        {
                            range: [34, 66],
                            title: "Business Strategist",
                            description:
                                "Those in this range are adept at crafting strategic blueprints for businesses. They possess a nuanced understanding of advanced entrepreneurial principles, requiring them to fine-tune their strategies and architectural skills for optimal business design.",
                        },
                        {
                            range: [67, 100],
                            title: "The Entrepreneurial Genius",
                            description:
                                "Individuals with high scores are geniuses in the art of entrepreneurship. They demonstrate a mastery of intricate business dynamics, innovation, and risk management. Their entrepreneurial acumen positions them as savants capable of successfully launching and managing complex startups.",
                        },
                    ],
                },
                {
                    assessmentName: "Entrepreneurship - Hard",
                    interceptions: [
                        {
                            range: [0, 33],
                            title: "The Business Explorer",
                            description:
                                "viduals in this range are navigating the challenging terrain of entrepreneurship. They may need to explore intricate business landscapes, identify advanced opportunities, and overcome substantial hurdles on their entrepreneurial journey.",
                        },
                        {
                            range: [34, 66],
                            title: "Business Strategist",
                            description:
                                "Those in this range are adept at crafting strategic blueprints for businesses. They possess a nuanced understanding of advanced entrepreneurial principles, requiring them to fine-tune their strategies and architectural skills for optimal business design.",
                        },
                        {
                            range: [67, 100],
                            title: "The Entrepreneurial Genius",
                            description:
                                "Individuals with high scores are geniuses in the art of entrepreneurship. They demonstrate a mastery of intricate business dynamics, innovation, and risk management. Their entrepreneurial acumen positions them as savants capable of successfully launching and managing complex startups.",
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<style scoped>
.interceptionsWrapper {
    width: 100%;
    padding: 3% 5%;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 1rem;
    flex-direction: row;
}
</style>
